import { Routes, Route, HashRouter, BrowserRouter } from "react-router-dom";
import "./App.css";
import Login from "./pages/Login";
import Register from "./pages/Register";
import { AppRoutes } from "./lib/constants";
import { LoginTokenContext } from "./contexts/LoginTokenContext";
import useToken from "./contexts/useToken";
import VesselLocation from "./pages/VesselLocation";
import AlertModal from "./components/AlertModal";
import { DigiPortPilotageDataContextProvider } from "./contexts/DigiPortPilotageDataContext";

function App() {
  const { token, setToken, encodedToken, setEncodedToken } = useToken();

  //Check if token is valid and not expired. If token is expired, users only have access to public routes
  if (!token || token.exp < Date.now() / 1000) {
    return (
      // Login Context ensures that routes/components within the context has access to the tokens for requesting
      <LoginTokenContext.Provider
        value={[token, setToken, encodedToken, setEncodedToken]}
      >
        <BrowserRouter>
          <Routes>
            <Route path={AppRoutes.Login} element={<Login />} />
            <Route path={AppRoutes.Register} element={<Register />} />
          </Routes>
        </BrowserRouter>
      </LoginTokenContext.Provider>
    );
  } else {
    return (
      <LoginTokenContext.Provider
        value={[token, setToken, encodedToken, setEncodedToken]}
      >
        <DigiPortPilotageDataContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path={AppRoutes.Login} element={<Login />} />
            <Route path={AppRoutes.Register} element={<Register />} />
            <Route
              path={AppRoutes.VesselQuery}
              element={<VesselLocation vessels={[]} />}
            />
          </Routes>
        </BrowserRouter>
        </DigiPortPilotageDataContextProvider>
      </LoginTokenContext.Provider>
    );
  }
}

export default App;
