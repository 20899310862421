import { LatLngExpression } from 'leaflet';
import React, { RefObject, useEffect, useState } from 'react'
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-rotatedmarker";
import styled from 'styled-components';
import { tileLayer } from '../lib/constants';
import VesselMapObj from '../lib/Interfaces/VesselMapObj';
import VesselMarker from './VesselMarker';
import { mapData } from "../lib/mapData";
import PolygonMarker from './PolygonMarker';



interface VesselMapV2Props {
    vessels: VesselMapObj[];
    sideBar: boolean;
    isLargeScreen: boolean;
    mapRef: RefObject<L.Map>;
    forceRefresh: number;
    vesselPopup: string;
    handleClosePopup: Function;
    userType: number;
}

const MapSection = styled.div`
    margin:auto;
`;

const vesselNowLogo = require('../assets/vessel_now_logo.png')

const VesselMapV2: React.FC<VesselMapV2Props> = ({
    vessels,
    sideBar,
    isLargeScreen,
    mapRef,
    forceRefresh,
    vesselPopup,
    handleClosePopup,
    userType
}) => {
    const [mapKey, setMapKey] = useState(0);
    const mapStyle = {
        width: (sideBar && isLargeScreen) ? "0" : "100vw",
        height: "100vh",
    };
    const [mapCenter, setMapCenter] = React.useState<LatLngExpression>([1.257167, 103.897]);

    //Force render component when screen changes size or when force refresh is called 
    useEffect(() => {
        if (mapRef.current) {
            setMapKey((prev) => prev + 1);
        }
    }, [isLargeScreen, forceRefresh]);

    return <>
        <MapSection className='relative z-10 overflow-hidden'>
            <MapContainer
                key={mapKey}
                ref={mapRef}
                center={mapCenter}
                zoom={11}
                scrollWheelZoom={true}
                style={mapStyle}
            >
                <TileLayer {...tileLayer} />
                {
                    //For each vessel in the fleet, render a marker for it
                    vessels.map((vessel: VesselMapObj) => {
                        if (vessel.visible && !vessel.loading && vessel.latitude !== "" && vessel.longitude !== "") {
                            return (
                            <VesselMarker
                                handleClosePopup={handleClosePopup} 
                                vesselPopup={vesselPopup}
                                key={vessel.id}
                                vessel={vessel}
                                coordinates={[vessel.latitude, vessel.longitude]}
                                rotation={vessel.course}
                                index={vessel.colorIndex}
                                userType={userType}
                            />
                            )
                        } else {
                            <></>
                        }
                    })
                }
                {
                //For each anchorage location, render a polygon marker for it
                mapData.map((data) => {
                    return (
                        <PolygonMarker
                            coordinates={data.coordinates}
                            name={data.name}
                        />
                    );
                })}
            </MapContainer>
        </MapSection>

        <img
            style={{
                height: 91,
                width: 225,
                pointerEvents: 'none'
            }}
            className="absolute bottom-1 left-1 z-40"
            alt="SGTraDex"
            src={vesselNowLogo}
        />
    </>

}

export default VesselMapV2;