import { Close } from "@mui/icons-material";
import VesselMapObj from "../lib/Interfaces/VesselMapObj";
import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import VesselAlertDisplayCard from "./VesselAlertDisplayCard";
import { enqueueSnackbar } from "notistack";

interface DeletionConfirmationModalProps {
  handleMultipleVesselRemove: Function;
  selectedVessels: VesselMapObj[];
  handleConfirmationModalClose: Function;
}

const DeletionConfirmationModal: React.FC<DeletionConfirmationModalProps> = ({
  handleMultipleVesselRemove,
  selectedVessels,
  handleConfirmationModalClose,
}) => {
  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div
          className="bg-white aspect-auto h-full lg:h-auto lg:aspect-video w-full lg:w-6/12 z-50 rounded shadow-lg overflow-hidden"
          style={{
            maxWidth: "1800px",
            maxHeight: "1013px",
          }}
        >
          <div className="flex flex-col items-center justify-center h-full mx-auto text-center px-10 relative">
            <Close
              className="absolute hover:cursor-pointer right-2 top-2"
              onClick={() => handleConfirmationModalClose()}
            />
            <div
              className="flex flex-row"
              style={{ height: "85%", width: "100%" }}
            >
              <div className="flex-1 my-auto">
                {selectedVessels.map((vessel) => {
                  return <VesselAlertDisplayCard vessel={vessel} />;
                })}
              </div>
              <div className="flex flex-col flex-1 px-4">
                <div className="my-auto ">
                  <h1 className=" text-xl">
                    Are you sure you would like to remove the following vessels?
                  </h1>
                  <div className="flex flex-row gap-5 my-5 justify-center">
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        handleMultipleVesselRemove();
                      }}
                    >
                      Remove
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "gray",
                      }}
                      onClick={() => {
                        handleConfirmationModalClose();
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeletionConfirmationModal;
