import React, { createContext, useEffect, useState } from "react";
import { DigiPortPilotageData } from "../lib/Interfaces/DigiPortPilotageData";

type ListOfDigiPortPilotageData = Record<DigiPortPilotageData['vessel_name'], DigiPortPilotageData>

type DigiPortPilotageDataContextProps = {
    isEnabledForUser: boolean,
    checkUserAccess: (userType: number) => void
    data: ListOfDigiPortPilotageData,
    handleSetData: (receivedData: DigiPortPilotageData) => void
    fetchData: (vesselName: string) => DigiPortPilotageData | null
    socketId: string | null,
    setSocketId: (id: string) => void
}

export const DigiPortPilotageDataContext = createContext<DigiPortPilotageDataContextProps>({
    isEnabledForUser: false,
    checkUserAccess: (userType: number) => null,
    data: {},
    handleSetData: (receivedData: DigiPortPilotageData) => null,
    fetchData: (vesselName: string) => null,
    socketId: "",
    setSocketId: (id: string) => null
})

export const DigiPortPilotageDataContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isEnabledForUser, setIsEnabledForUser] = useState<boolean>(false)
    const [socketId, setSocketId] = useState<string | null>(null)

    // initialise context with stale data from localstorage
    const [data, setData] = useState<ListOfDigiPortPilotageData>(() => {
        if (typeof window !== "undefined") {
            const localStorageStaleData = window.localStorage.getItem("DIGIPORT_VSIP_DATA");
            return localStorageStaleData ? JSON.parse(localStorageStaleData) as ListOfDigiPortPilotageData : {};
        }
        return {};
    })

    // whenever `data` is changed, write to localstorage
    useEffect(() => {
        if (typeof window !== undefined) {
            window.localStorage.setItem('DIGIPORT_VSIP_DATA', JSON.stringify(data))
        } else {
            console.warn('window is not defined yet, did not write to localstorage', data)
        }
    }, [data])

    const handleSetData = (receivedData: DigiPortPilotageData) => {        
        setData(prevData => {
            return {
                ...prevData,
                [receivedData.vessel_name]: receivedData
            }
        })
    }

    const fetchData = (vesselName: string) => {
        return data[vesselName] ?? null
    }

    const handleCheckIfUserIsEnabled = (userType: number) => {
        if (!isEnabledForUser && userType === 3) {
            setIsEnabledForUser(true)
        }
    }

    return (
        <DigiPortPilotageDataContext.Provider
            value={{
                isEnabledForUser,
                checkUserAccess: handleCheckIfUserIsEnabled,
                socketId,
                setSocketId,
                data,
                handleSetData,
                fetchData
            }}
        >
            {children}
        </DigiPortPilotageDataContext.Provider>
    )
}  