import React, { useContext, useRef, useState } from "react";
import {
  sharedButtonStyle,
  sharedFlexCenter,
  Image,
  sharedFlexSpaceBetween,
} from "../styles/global";
import { API_Methods, AlertType, AppRoutes, formFieldTypes } from "../lib/constants";
import styled, { css } from "styled-components";
import AppColors from "../styles/colors";
import { FormTitle, LogoContainer } from "./Login";
import { useNavigate } from "react-router-dom";
import { useMakePOSTRequest } from "../hooks/useMakePostRequest";
import { useResetAtom } from "jotai/utils";
import { popupAtom } from "../jotai/store";
import { useAtom } from "jotai";
import Popup from "../components/Popup";
import { LoginTokenContext } from "../contexts/LoginTokenContext";
import RegisterFormController from "../components/RegisterFormController";
import TermsAndConditionModal from "../components/TermsAndConditionModal";

const vesselNowLogo = require('../assets/vessel_now_logo.png')

function Register() {
  const navigate = useNavigate();
  const [registerUser] = useMakePOSTRequest();
  const resetPopup = useResetAtom(popupAtom);
  const [popupData, setPopupData] = useAtom(popupAtom);
  const [openTandC, setOpenTandC] = useState(false);
  const [formData, setFormData] = useState();
  const [setEncodedToken, encodedToken, setToken, token] = useContext(LoginTokenContext)
  const alertMessage = useRef({
    type: "",
    message: "",
    action: () => { }
  });
  const { input, password, email, submit, text, url } = formFieldTypes;

  const uenValidation = (text: string) => {
    const uenRegex = /^\d{10}$/
    return !uenRegex.test(text)
  }

  //Fields of registration form
  const formFields = {
    fields: [
      {
        name: "email",
        label: "Email",
        placeholder: "Email",
        defaultValue: "",
        type: input,
        inputType: email,
        style: InputStyle,
        enableInputStyleWithValue: true,
      },
      {
        name: "password",
        label: "Password",
        placeholder: "Password",
        defaultValue: "",
        inputType: password,
        type: password,
        style: InputStyle,
        enableInputStyleWithValue: true,
      }, {
        name: "company_name",
        label: "Company Name",
        placeholder: "Enter your Company Name",
        defaultValue: "",
        type: input,
        inputType: text,
        style: InputStyle,
        enableInputStyleWithValue: true,
      }, {
        name: "company_UEN",
        label: "Company UEN",
        placeholder: "Enter Company UEN",
        defaultValue: "",
        type: input,
        inputType: text,
        style: InputStyle,
        enableInputStyleWithValue: true,
        validation: uenValidation,
        helperText: "Invalid UEN"
      },

    ],
    buttons: [
      {
        name: "Next",
        type: submit,
        onSubmitHandler: (data: any) => {
          if (handlePreTandC(data)) {
            setOpenTandC(true)
            setFormData(data)
          }
        },
        style: btnStyle,
      },
    ],
  };

  //Called when user accepts T and C
  const handleRegister = async (data: any) => {
    //Field validation check
    if (
      (data.email == "" ||
        data.password == "" ||
        data.company_UEN == "" ||
        data.company_name == ""
      )
    ) {
      alertMessage.current = {
        type: AlertType.Error,
        message: "Fields cannot be empty",
        action: resetPopup
      }
      handlePopup();
      return;
    }
    try {
      let requestData = {
        email: data.email,
        password: data.password,
        company_UEN: data.company_UEN,
        company_name: data.company_name,
      };
      let res: any = await registerUser(API_Methods.Register, requestData)
      if (!res.error) {
        //Alert user of successful register attempt
        alertMessage.current = {
          type: AlertType.Success,
          message: "Registered Successfully, Login now",
          action: () => {
            navigate(AppRoutes.Login)
            resetPopup()
          }
        }
        handlePopup()
      } else if (res.error) {
        //Alert user of unsuccessful register attempt
        alertMessage.current = {
          type: AlertType.Error,
          message: "Your email exists in database! Please reach out to Admin if you need assistance",
          action: resetPopup
        }
        handlePopup();
      }
    } catch (error) {
      alertMessage.current = {
        type: AlertType.Error,
        message: "Something went wrong. Try again",
        action: resetPopup
      }
      handlePopup();
    }
  };

  //Called after user fills main form. Users are required to accept T and C before registration request is sent.
  const handlePreTandC = (data: any) => {
    //Form field validation
    if (
      (
        data.email == "" ||
        data.password == "" ||
        data.company_UEN == "" ||
        data.company_name == ""
      )
    ) {
      alertMessage.current = {
        type: AlertType.Error,
        message: "Fields cannot be empty",
        action: resetPopup
      }
      handlePopup();
      return false;
    } else if (
      uenValidation(data.company_UEN)
    ) {
      alertMessage.current = {
        type: AlertType.Error,
        message: "Invalid UEN",
        action: resetPopup
      }
      handlePopup();
      return;
    }

    return true
  };

  function handlePopup() {
    setPopupData({
      isOpen: true,
      message: alertMessage.current.message,
      type: alertMessage.current.type,
      btnHandler: alertMessage.current.action,
    });
  }

  return (
    <RegisterPage className="min-h-screen pb-5">
      <LogoContainer className="my-6">
        <Image src={vesselNowLogo} />
      </LogoContainer>
      <div style={{
        width: '800px'
      }}
        className=" max-w-full bg-white px-6 py-4">
        <FormContainer>
          <Title>Registration Form</Title>
          <RegisterFormController formFields={formFields} isFormRow />
        </FormContainer>
        <Footer className="text-center text-sm">Already have an account? <Link onClick={() => navigate("/")}>Login here</Link></Footer>
        {popupData.isOpen && <Popup />}
      </div>
      {openTandC ? <TermsAndConditionModal handleAgreeTandC={async () => {
        await handleRegister(formData)
        setOpenTandC(false)

      }} closeTandCModal={() => { setOpenTandC(false) }} /> : <></>}
    </RegisterPage>
  );
}

export default Register;

const RegisterPage = styled.div`
display: flex;
flex-direction: column; /* Stack children vertically */
align-items: center;
justify-content: flex-start;
min-height: 100vh;
padding: 0;
background: linear-gradient(to bottom, #1845ad, #b92f90);
`;

const Header = styled.div`
  width: 100%;
  ${sharedFlexSpaceBetween}
  align-self: flex-start;
`;

const Footer = styled.div`
  padding: 0 0 0 1rem;
`;
const Title = styled(FormTitle)`
  text-align: center;
  line-height: 1.5;
  padding: 0;
  font-size: 60px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  background: -webkit-linear-gradient(right, #290fc0, #9f01ea, #56d8e4, #9f01ea);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Link = styled.a`
  font-weight: 700;
  cursor: pointer;
  text-decoration: underline;
  color: ${AppColors.ThemeBlue}
`;

const SideTitle = styled.div`
  align-self: flex-end;
  padding: 0 2rem 3rem 0;
`;

const FormContainer = styled.div`
  ${sharedFlexCenter}
  flex-direction: column;
`;

const InputStyle = css`
  background-color: ${AppColors.ThemeLightTransparencyBlack};
  padding: 0.75rem 1rem;
  border-width: 0 0 2px 0px;
  margin: 0.5rem 0;
  border-color: ${AppColors.ThemeBlack};
  border-radius: 1rem;
  outline: none;
  width: 100%;
  &:focus {
    border-color: ${AppColors.ThemeBlue};
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  &::placeholder {
    color: ${AppColors.ThemeLightBlack};
  }
`;

const btnStyle = css`
  margin: 2rem;
  width:15rem;
  height: 100%;
  background: ${AppColors.ThemeBlueShadow};
  color: black;
  transition: all 0.4s;
  &:hover {
    background: ${AppColors.ThemeBlue};
    color: white;
  }
`;

const SideLink = styled(Link)`
  color: ${AppColors.White};
`;
