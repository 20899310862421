import { Close, Logout } from "@mui/icons-material";
import {
  Button,
  Input,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import useToken from "../contexts/useToken";
import { getVesselsDueToArrive } from "../api/API";
import VesselDueToArrive from "../lib/Interfaces/VesselDueToArrive";
import { extractDateRange } from "../lib/constants";

interface DueToArriveSideBarContentProps {
  logout: Function;
  handleLogout: Function;
  isLargeScreen: boolean;
  handleCloseSidebar: Function;
}
type SearchKey = "vesselName" | "imoNumber" | "callSign" | "flag";

const DueToArriveSideBarContent: React.FC<DueToArriveSideBarContentProps> = ({
  logout,
  handleLogout,
  isLargeScreen,
  handleCloseSidebar,
}) => {
  const { token, setToken, encodedToken, setEncodedToken } = useToken();
  useEffect(() => {
    async function initDueToArrive() {
      const data = await getVesselsDueToArrive(token);
      setVessels(data);
      setExtractedDates(extractDateRange(data))
    }
    initDueToArrive();
  }, []);
  const [searchBy, setSearchBy] = useState("vesselName");
  const [filteredDate, setFilteredDate] = useState("all");
  const [filterValue, setFilterValue] = useState("");
  const [extractedDates, setExtractedDates] = useState<string[]>([])
  const [vessels, setVessels] = useState<VesselDueToArrive[]>([]);
  function handleSearchByChange(searchByValue: string) {
    setSearchBy(searchByValue);
  }

  function handleFilteredDateChange(filteredDateValue: string) {
    setFilteredDate(filteredDateValue);
  }

  const filteredVessels = vessels.filter((vessel) =>
    vessel[searchBy as keyof VesselDueToArrive]
      .toString()
      .toLowerCase()
      .includes(filterValue.toLowerCase()) && (filteredDate === "all" || vessel.dueToArriveTime.toDateString() === filteredDate)
  );

  return (
    <>
      <div className="text-center p-4">
        <div
          className={`flex ${
            isLargeScreen ? "flex-row justify-between" : "justify-end"
          } mb-5 `}
        >
          {isLargeScreen && (
            <Close
              htmlColor="white"
              className="hover:cursor-pointer mr-4"
              onClick={() => handleCloseSidebar()}
            />
          )}
          <Button
            className="w-28 py-2"
            color="error"
            variant="contained"
            startIcon={<Logout />}
            onClick={() => {
              handleLogout();
            }}
          >
            Logout
          </Button>
        </div>
        <h1 className="text-white h-min inline-block whitespace-nowrap text-2xl xl:text-3xl">
          Due To Arrive
        </h1>
        <div className="flex flex-row text-white items-baseline my-5">
          <p className="mr-3">Search by: </p>
          <Input
            className={`rounded bg-white mr-4`}
            disableUnderline
            placeholder="Enter filter term"
            value={filterValue}
            onChange={(e) => {
              setFilterValue(e.target.value);
            }}
            sx={{ fontSize: "14px" }}
            autoFocus
          />
          <Select
            className=" bg-white text-white h-6"
            value={searchBy}
            label="Search by"
            onChange={(e) => handleSearchByChange(e.target.value)}
          >
            <MenuItem value={"vesselName"}>Name</MenuItem>
            <MenuItem value={"imoNumber"}>IMO</MenuItem>
            <MenuItem value={"callSign"}>Call Sign</MenuItem>
            <MenuItem value={"flag"}>Flag</MenuItem>
          </Select>
        </div>
        <div className="flex flex-row text-white items-baseline my-5">
          <p className="mr-3">Date: </p>
          <Select
            className=" bg-white text-white h-6"
            value={filteredDate}
            label="Date"
            onChange={(e) => handleFilteredDateChange(e.target.value)}
          >
            <MenuItem value={"all"}>All</MenuItem>
            {extractedDates.map(date => {
              return <MenuItem value={date}>{date}</MenuItem>
            })}

          </Select>
        </div>
        <div className="flex-grow overflow-hidden">
          <TableContainer
            component={Paper}
            style={{
              height: "calc(100vh - 250px)",
              maxHeight: "calc(100vh - 200px)",
              border: "1px solid rgba(224, 224, 224, 1)",
              overflowY: "auto",
            }}
            sx={{
              "&::-webkit-scrollbar": {
                width: "6px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "3px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
              scrollbarWidth: "thin",
              scrollbarColor: "#888 #f1f1f1",
            }}
          >
            <Table
              size="small"
              stickyHeader
              sx={{
                borderCollapse: "separate",
                borderSpacing: 0,
                "& .MuiTableCell-root": {
                  padding: "6px",
                  fontSize: "0.75rem",
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  "&:last-child": {
                    borderRight: "none",
                  },
                },
                "& .MuiTableCell-head": {
                  background: "white",
                  fontWeight: "bold",
                  zIndex: 1,
                  borderBottom: "2px solid rgba(224, 224, 224, 1)",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>IMO</TableCell>
                  <TableCell>Call Sign</TableCell>
                  <TableCell>Flag</TableCell>
                  <TableCell>Due To Arrive</TableCell>
                  {/* <TableCell>From</TableCell> */}
                  <TableCell>To</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredVessels.map((vessel, index) => (
                  <TableRow key={index}>
                    <TableCell>{vessel.vesselName}</TableCell>
                    <TableCell>{vessel.imoNumber}</TableCell>
                    <TableCell>{vessel.callSign}</TableCell>
                    <TableCell>{vessel.flag}</TableCell>
                    <TableCell>
                      {new Date(vessel.dueToArriveTime).toLocaleString([], {
                        dateStyle: "short",
                        timeStyle: "short",
                      })}
                    </TableCell>
                    {/* <TableCell>{vessel.locationFrom}</TableCell> */}
                    <TableCell>{vessel.locationTo}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default DueToArriveSideBarContent;
