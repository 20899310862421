import { useEffect, useRef, useState } from "react"
import { Document, Page } from 'react-pdf'
import { pdfjs } from 'react-pdf'
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Button } from "@mui/material";
import { Close } from "@mui/icons-material";

//Boilerplate code. Used to call PDF builder worker 
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();

//Load in PDF from assets folder
const tAndCUrl = require("../assets/VesselNow_T_AND_C.pdf")

interface TermsAndConditionModalProps {
    closeTandCModal: Function;
    handleAgreeTandC: Function;
}


const TermsAndConditionModal: React.FC<TermsAndConditionModalProps> = ({ closeTandCModal, handleAgreeTandC }) => {
    const [readTandC, setReadTandC] = useState<boolean>(false);
    const viewerRef = useRef(null);

    //Called each time the page is changed
    const handlePageChange = (e: any) => {
        //If the user is in the final page of the document, allow them to accept T and C
        if (e.currentPage == (e.doc._pdfInfo.numPages - 1)) {
            setReadTandC(true)
        } else {
            setReadTandC(false)
        }

    };
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white aspect-auto h-full lg:h-auto lg:aspect-video w-full lg:w-9/12 z-50 rounded shadow-lg overflow-hidden py-20 relative"
                style={{
                    maxWidth: '1800px',
                    maxHeight: '1013px'
                }}
            >
                <Close className="absolute hover:cursor-pointer right-2 top-2" onClick={() => closeTandCModal()} />
                <h1 className="text-center text-4xl font-bold mb-5">Terms and Condition</h1>

                <div className="w-full mx-auto h-3/4 border-solid border"
                    style={{
                        maxWidth: '1000px',
                    }}
                >
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        <div className="relative h-full w-full">
                            <Viewer
                                onPageChange={handlePageChange}
                                fileUrl={tAndCUrl}
                                ref={viewerRef}
                            />
                            <div className="my-5 text-center">
                                <Button onClick={() => {handleAgreeTandC()}} className="w-64" size="medium" variant="contained" color="success" disabled={!readTandC}>I Agree</Button>
                            </div>
                        </div>
                    </Worker>


                </div>

            </div>
        </div>

    )
}

export default TermsAndConditionModal