import { isConstructorDeclaration } from "typescript";
import { API_ENDPOINT, API_Response_Success, Response_Message } from "../lib/constants";


//Template for POST request
export function useMakePOSTRequest() {
  function makePOSTRequest(methodEndpoint: any, params?: any) {
    return new Promise((resolve, reject) => {
      let data = "";
      //Construct payload
      for (let key in params) {
        data += key + "=" + params[key] + "&";
      }
      const requestOptions: any = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: data,
      };
      //Launch request
      fetch(API_ENDPOINT + methodEndpoint, requestOptions)
        .then(async (response) => {
          if (response.status == 200) {
            const contentType = response.headers.get("content-type");
            //Adjust response type
            if (contentType && contentType.includes("application/json")) {
              const json = await response.json();
              resolve(json);
            } else {
              const text = await response.text();
              resolve(text);
            }
          } else {
            const errorMessage = await response.text();
            reject(new Error(`Request failed with status ${response.status}. ${errorMessage}`))
          }
        })
        .catch((error) => reject(error));
    });
  }
  return [makePOSTRequest]
}
