import { Circle, Close, Logout } from "@mui/icons-material";
import { Alert } from "../lib/Interfaces/Alert";
import { Button } from "@mui/material";

interface AlertSidebarContent {
  alert: Alert;
  clearAlert: Function;
  logout: Function;
  handleLogout: Function;
  isLargeScreen: boolean;
  handleCloseSidebar: Function;
}

const AlertSideBarContent: React.FC<AlertSidebarContent> = ({
  alert,
  clearAlert,
  logout,
  handleLogout,
  isLargeScreen,
  handleCloseSidebar,
}) => {
  return (
    <>
      <div className="text-center p-4">
        <div
          className={`flex ${
            isLargeScreen ? "flex-row justify-between" : "justify-end"
          } mb-5 `}
        >
          {isLargeScreen && (
            <Close
              htmlColor="white"
              className="hover:cursor-pointer mr-4"
              onClick={() => handleCloseSidebar()}
            />
          )}
          <Button
            className="w-28 py-2"
            color="error"
            variant="contained"
            startIcon={<Logout />}
            onClick={() => {
              handleLogout();
            }}
          >
            Logout
          </Button>
        </div>
        <h1 className="text-white h-min inline-block whitespace-nowrap text-2xl xl:text-3xl">
          Active Alerts
        </h1>
      </div>
      {alert ? (
        <div className="px-8 text-white">
          <h2>Next Alarm :</h2>
          <p className="text-2xl font-bold text-center my-2">
            {alert.alarmTime.toLocaleString()}
          </p>
          <h2>
            Alert Type:
            {alert.alertType.locationAlert ? (
              <div className="whitespace-nowrap border border-solid border-white p-2 rounded-md bg-white text-black my-2 align-middle">
                <Circle fontSize="small" htmlColor="green" className="m-1" />
                <p className="my-1 w-min inline">Location Alert</p>
              </div>
            ) : (
              <></>
            )}
            {alert.alertType.pilotageAlert ? (
              <div className="whitespace-nowrap border border-solid border-white p-2 rounded-md bg-white text-black my-2 align-middle">
                <Circle fontSize="small" htmlColor="green" className="m-1" />
                <p className="my-1 w-min inline">Pilotage Alert</p>
              </div>
            ) : (
              <></>
            )}
          </h2>
          <div
            className="whitespace-nowrap"
            style={{ maxHeight: "calc(100vh - 100px)", overflowY: "auto" }}
          >
            <h2>
              Vessels
              {alert.stale.map((vessel) => {
                return (
                  <div className="text-nowrap bg-white rounded-sm text-black p-2 my-2 whitespace-nowrap">
                    <p className="font-bold whitespace-nowrap">
                      {vessel.name} - {vessel.callsign}
                    </p>
                    <p className="whitespace-nowrap">IMO: {vessel.IMO}</p>
                  </div>
                );
              })}
            </h2>
          </div>
          <div className="text-center whitespace-nowrap">
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                clearAlert();
              }}
            >
              Clear Alert
            </Button>
          </div>
        </div>
      ) : (
        <div className="text-white text-center">- No active alerts -</div>
      )}
    </>
  );
};
export default AlertSideBarContent;
