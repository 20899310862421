import React, { ReactNode, useContext, useEffect, useRef } from "react";
import { Marker, Popup } from "react-leaflet";
import L from "leaflet";
import {
  vesselInfoDateFormat,
  getDaysDiff,
  getTimeInHHMMFormat,
  formatTimeDifference,
} from "../lib/constants";
import VesselMapObj from "../lib/Interfaces/VesselMapObj";
import styled from "styled-components";
import PilotageInfo from "../lib/Interfaces/PilotageInfo";
import { Square } from "@mui/icons-material";
import { DigiPortPilotageDataComponent } from "./DigiPortPilotageData";
import { DigiPortPilotageDataContext } from "../contexts/DigiPortPilotageDataContext";

interface VesselMarkerProps {
  coordinates: any;
  vessel: VesselMapObj;
  rotation: Number;
  index: number;
  vesselPopup: string;
  handleClosePopup: Function;
  userType: number;
}

const VesselInfoText: React.FC<{
  children?: ReactNode;
  className?: string;
  title: string;
}> = ({ children, className, title }) => {
  return (
    <p
      className={className}
      style={{
        margin: 0,
      }}
    >
      <span className="font-bold">{title}:</span> {children}
    </p>
  );
};

const VesselMarker: React.FC<VesselMarkerProps> = ({
  coordinates,
  vessel,
  rotation,
  index,
  vesselPopup,
  handleClosePopup,
  userType
}) => {
  const markerRef = useRef<L.Marker>(null);
  const { isEnabledForUser } = useContext(DigiPortPilotageDataContext)

  useEffect(() => {
    if (markerRef.current && vesselPopup == vessel.IMO) {
      markerRef.current.openPopup();
    }
  }, [vesselPopup]);
  
  const pilotageInfo = vessel.pilotageInfo;
  const marker = require(`../assets/arrow${index}.png`);
  const customMarkerIcon = new L.Icon({
    iconUrl: marker,
    iconSize: [20, 20], // Set the icon size
    iconAnchor: [0, 0],
  });
  const diff = vessel.timeStamp
    ? Date.now() - new Date(vessel.timeStamp).getTime()
    : null;
  const voyageInfoDisplay = {
    Callsign: vessel.callsign,
    Speed: vessel.speed,
    Destination: vessel.destination,
    ETA: vessel.eta ? new Date(vessel.eta) : null,
    ETD: vessel.etd ? new Date(vessel.etd) : null,
  };
  const pilotageInfoDisplay = pilotageInfo?.map((pi) => {
    return {
      "Confirmed Service Time":
        pilotageInfo && pi.pilotage_cst_dt_time
          ? new Date(pi.pilotage_cst_dt_time)
          : null,
      "Arrival Time":
        pilotageInfo && pi.pilotage_arrival_dt_time
          ? new Date(pi.pilotage_arrival_dt_time)
          : null,
      "Onboard Time":
        pilotageInfo && pi.pilotage_onboard_dt_time
          ? new Date(pi.pilotage_onboard_dt_time)
          : null,
      "Start Time":
        pilotageInfo && pi.pilotage_start_dt_time
          ? new Date(pi.pilotage_start_dt_time)
          : null,
      "End Time":
        pilotageInfo && pi.pilotage_end_dt_time
          ? new Date(pi.pilotage_end_dt_time)
          : null,
      "Location to Code": pi?.pilotage_loc_to_code,
      "Location from Code": pi?.pilotage_loc_from_code,
    };
  });

  const findClosestFutureCstTime = (pilotageInfo: PilotageInfo[]) => {
    const now = new Date(); // Get current time
    let closestFutureCstTime = null;
    let minDiff = Infinity;

    pilotageInfo.forEach((pi) => {
      const cstTime = pi.pilotage_cst_dt_time;
      const diff =
        ((cstTime ? cstTime.getTime() : Infinity) - now.getTime()) /
        (1000 * 60); // Difference in minutes
      if (diff > 0 && diff < minDiff) {
        minDiff = diff;
        closestFutureCstTime = pi;
      }
    });

    return (
      closestFutureCstTime ?? {
        pilotage_cst_dt_time: new Date(),
      }
    );
  };
  const closestFutureCstTime = findClosestFutureCstTime(
    vessel.pilotageInfo ?? []
  );

  L.Marker.mergeOptions({
    rotationAngle: rotation,
    rotationOrigin: "centre centre",
  });

  return (
    <Marker
      position={coordinates}
      icon={customMarkerIcon}
      ref={markerRef}
      eventHandlers={{
        popupclose: () => {
          handleClosePopup(vessel);
        },
      }}
    >
      <Popup maxWidth={800} maxHeight={1000}>
        <div className="flex flex-col mx-auto bg-white">
          <h1 className="text-2xl font-semibold">
            {vessel.name} {vessel.callsign ? `(${vessel.callsign})` : ""}{" "}
            {vessel.flag ? `- ${vessel.flag}` : ""}
          </h1>
          <div>
            <p
              style={{
                marginTop: "0.5rem",
                marginBottom: "0.25rem",
              }}
              className="font-bold text-center underline"
            >
              Pilotage Information
            </p>
            {vessel.hasPilotageData ? (
              pilotageInfo &&
              pilotageInfo.length > 0 &&
              vessel.hasPilotageData ? (
                <>
                  <h1 className=" text-center my-2 font-bold">
                    Vessel Movement
                  </h1>
                  <table
                    style={{
                      border: "1px solid black",
                      margin: "0 auto",
                      borderCollapse: "collapse",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{ padding: "8px", border: "1px solid black" }}
                        >
                          From
                        </th>
                        <th
                          style={{ padding: "8px", border: "1px solid black" }}
                        >
                          To
                        </th>
                        <th
                          style={{ padding: "8px", border: "1px solid black" }}
                        >
                          Service Request Time
                        </th>
                        <th
                          style={{ padding: "8px", border: "1px solid black" }}
                        >
                          Arrival Time
                        </th>
                        <th
                          style={{ padding: "8px", border: "1px solid black" }}
                        >
                          Onboard Time
                        </th>
                        <th
                          style={{ padding: "8px", border: "1px solid black" }}
                        >
                          Start Time
                        </th>
                        <th
                          style={{ padding: "8px", border: "1px solid black" }}
                        >
                          End Time
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pilotageInfo.map((pi, index) => (
                        <tr
                          key={index}
                          style={{ borderBottom: "1px solid black" }}
                          className={
                            closestFutureCstTime.pilotage_cst_dt_time.getTime() ===
                            pi.pilotage_cst_dt_time?.getTime()
                              ? "bg-yellow-200"
                              : ""
                          }
                        >
                          <td
                            style={{
                              padding: "8px",
                              border: "1px solid black",
                            }}
                          >
                            {pi.pilotage_loc_from_code}
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              border: "1px solid black",
                            }}
                          >
                            {pi.pilotage_loc_to_code}
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              border: "1px solid black",
                            }}
                          >
                            {vesselInfoDateFormat(pi.pilotage_cst_dt_time)}
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              border: "1px solid black",
                            }}
                          >
                            {getTimeInHHMMFormat(pi.pilotage_arrival_dt_time)}
                            {getDaysDiff(
                              pi.pilotage_cst_dt_time,
                              pi.pilotage_arrival_dt_time
                            ) > 0 ? (
                              <sup>
                                +
                                {getDaysDiff(
                                  pi.pilotage_cst_dt_time,
                                  pi.pilotage_arrival_dt_time
                                )}
                              </sup>
                            ) : null}
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              border: "1px solid black",
                            }}
                          >
                            {getTimeInHHMMFormat(pi.pilotage_onboard_dt_time)}
                            {getDaysDiff(
                              pi.pilotage_cst_dt_time,
                              pi.pilotage_onboard_dt_time
                            ) > 0 ? (
                              <sup>
                                +
                                {getDaysDiff(
                                  pi.pilotage_cst_dt_time,
                                  pi.pilotage_onboard_dt_time
                                )}
                              </sup>
                            ) : null}
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              border: "1px solid black",
                            }}
                          >
                            {getTimeInHHMMFormat(pi.pilotage_start_dt_time)}
                            {getDaysDiff(
                              pi.pilotage_cst_dt_time,
                              pi.pilotage_start_dt_time
                            ) > 0 ? (
                              <sup>
                                +
                                {getDaysDiff(
                                  pi.pilotage_cst_dt_time,
                                  pi.pilotage_start_dt_time
                                )}
                              </sup>
                            ) : null}
                          </td>
                          <td
                            style={{
                              padding: "8px",
                              border: "1px solid black",
                            }}
                          >
                            {getTimeInHHMMFormat(pi.pilotage_end_dt_time)}
                            {getDaysDiff(
                              pi.pilotage_cst_dt_time,
                              pi.pilotage_end_dt_time
                            ) > 0 ? (
                              <sup>
                                +
                                {getDaysDiff(
                                  pi.pilotage_cst_dt_time,
                                  pi.pilotage_end_dt_time
                                )}
                              </sup>
                            ) : null}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div>
                    <p>
                      *{" "}
                      <Square className="text-yellow-200 border border-black bg-yellow-200" />{" "}
                      - Upcoming vessel movement
                    </p>
                  </div>
                </>
              ) : (
                "Data is in progress..."
              )
            ) : (
              "No Pilotage Data Found"
            )}
          </div>
          <div>
            <p
              style={{
                marginTop: "0.5rem",
                marginBottom: "0.25rem",
              }}
              className="font-bold text-center underline"
            >
              Voyage Information (VesselFinder)
            </p>
            <p
              className=" text-gray-600 text-center"
              style={{
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
              }}
            >
              <span className="font-bold">Last Updated: </span>{diff ? formatTimeDifference(diff) : "-"}
            </p>
            <div className="flex flex-row">
              <div className="flex-1">
                <VesselInfoText title="Speed">{vessel.speed}</VesselInfoText>
                <VesselInfoText title="Destination">
                  {voyageInfoDisplay.Destination !== "" && voyageInfoDisplay
                    ? voyageInfoDisplay.Destination
                    : "-"}
                </VesselInfoText>
                <VesselInfoText title="ETA">
                  {vesselInfoDateFormat(voyageInfoDisplay.ETA)}
                </VesselInfoText>
                {/* <VesselInfoText title="ETD">
                  {vesselInfoDateFormat(voyageInfoDisplay.ETD)}
                </VesselInfoText> */}
              </div>
              <div className="flex-1">
                <VesselInfoText title="Ex Names">
                  {vessel.aliases && vessel.aliases.length > 0 ? (
                    vessel.aliases.join(",")
                  ) : (
                    <>-</>
                  )}
                </VesselInfoText>
                <VesselInfoText title="IMO">{vessel.IMO ?? "-"}</VesselInfoText>
                <VesselInfoText title="MMSI">
                  {vessel.MMSI ?? "-"}
                </VesselInfoText>
                <VesselInfoText title="Type">
                  {vessel.type ?? "-"}
                </VesselInfoText>
              </div>
            </div>
            <p
              style={{
                marginTop: "0.5rem",
                marginBottom: "0.25rem",
              }}
              className="font-bold text-center underline"
            >
              MPA Information
            </p>
            <div className="flex flex-row">
              <div className="flex-1">
                <VesselInfoText title="Due To Arrive">
                  {vesselInfoDateFormat(vessel.dueToArrive)}
                </VesselInfoText>
                <VesselInfoText title="Location From">
                  {vessel.locationFrom == "" ? "-" : vessel.locationFrom}
                </VesselInfoText>
              </div>
              <div className="flex-1">
                <VesselInfoText title="Due To Depart">
                  {vesselInfoDateFormat(vessel.dueToDepart)}
                </VesselInfoText>
                <VesselInfoText title="Location To">
                  {vessel.locationTo == "" ? "-" : vessel.locationTo}
                </VesselInfoText>
              </div>
            </div>
            {
              isEnabledForUser && (
                <DigiPortPilotageDataComponent vesselName={vessel.name} />
              )
            }
          </div>
        </div>
      </Popup>
    </Marker>
  );
};

export default React.memo(VesselMarker);
