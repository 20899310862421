import styled from "styled-components";
import {
  sharedButtonStyle,
  Image,
  sharedFlexSpaceBetween,
  Section,
} from "../styles/global";
import AppColors from "../styles/colors";
import FormController from "../components/FormController";
import {
  API_Methods,
  API_Response_OK,
  AlertType,
  AppRoutes,
  formFieldTypes,
  supportEmail,
} from "../lib/constants";
import { useNavigate } from "react-router-dom";
import { useMakePOSTRequest } from "../hooks/useMakePostRequest";
import Popup from "../components/Popup";
import { useResetAtom } from "jotai/utils";
import { popupAtom } from "../jotai/store";
import { useAtom } from "jotai";
import { useContext, useEffect, useRef } from "react";
import { LoginTokenContext } from "../contexts/LoginTokenContext";
const vesselNowLogo = require('../assets/vessel_now_logo.png')

//Login page
function Login() {
  const navigate = useNavigate();
  const [getLogin] = useMakePOSTRequest();
  const { input, password, email, submit } = formFieldTypes;
  const resetPopup = useResetAtom(popupAtom);
  const [popupData, setPopupData] = useAtom(popupAtom);
  const alertMessage = useRef("");
  //Extract tokens from context for use
  const [token, setToken, encodedToken, setEncodedToken] = useContext(LoginTokenContext)

  useEffect(() => {
    localStorage.clear()
  }, [])

  //Form fields for the login form
  const formFields = {
    fields: [
      {
        name: "email",
        label: "Email",
        placeholder: "Email",
        defaultValue: "",
        type: input,
        inputType: email,
      },
      {
        name: "password",
        label: "Password",
        placeholder: "Password",
        defaultValue: "",
        inputType: password,
        type: password,
      },
    ],
    buttons: [
      {
        name: "Log In",
        type: submit,
        onSubmitHandler: (data: any) => handleLogin(data),
        style: `${sharedButtonStyle}
        width: 100%;
        background-color: #088f72;
        color: #ffffff;`,
      },
      {
        name: "Register",
        type: submit,
        onSubmitHandler: () => navigate(AppRoutes.Register),
        style: `${sharedButtonStyle}
        width: 100%;
        background-color: #000000;
        color: #ffffff;`,
      },
    ],
  };

  //Called when user logs in
  const handleLogin = async (data: any) => {
    //Field validation checks
    if (data.email == "") {
      alertMessage.current = "Email cannot be empty";
      handlePopupData();
      return;
    } else if (data.password == "") {
      alertMessage.current = "Password cannot be empty";
      handlePopupData();
      return;
    }
    try {
      //Call to backend for login validation
      let res: any = await getLogin(API_Methods.Login, {
        email: data.email,
        password: data.password,
      });
      if (res) {
        //Save token and go to map page if the login is successful
        setToken(res.access_token)
        navigate(AppRoutes.VesselQuery);
      }

    } catch (error) {
      console.log(error)
      alertMessage.current = "Login Failed! Try Again";
      handlePopupData();
    }
  };

  //Opens popup
  function handlePopupData() {
    setPopupData({
      isOpen: true,
      message: alertMessage.current,
      type: AlertType.Error,
      btnHandler: resetPopup,
    });
  }

  return (
    <Section className="relative">
      <LogoContainer>
        <Image className="my-4" src={vesselNowLogo} />
      </LogoContainer>
      <FormContainer className="px-10 rounded-lg">
        <FormTitle className="text-2xl font-bold text-white">Login Here</FormTitle>
        <FormController formFields={formFields} />
      </FormContainer>
      <h1 className="text-sm my-3 text-white">Experiencing issues? Contact: <a className="underline hover:text-blue-400" href={`mailto:${supportEmail}?subject=Support%20required%20for%20Vessel%20Now`}>{supportEmail}</a></h1>
      {popupData.isOpen && <Popup />}
    </Section>
  );
}

export default Login;

export const LogoContainer = styled.div`
  width: 12rem;
  padding: 0rem 1rem;
`;

const FormContainer = styled.div`
  ${sharedFlexSpaceBetween}
  flex-direction: column;
  width: 30rem;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.13);
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
`;
export const FormTitle = styled.h1`
  padding: 0.5rem 2rem;
  font-size: 32px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
`;


