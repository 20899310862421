import { jwtDecode } from "jwt-decode";
import ArrowColor from "./Interfaces/ArrowColor";
import Bookmark from "./Interfaces/Bookmark";
import PilotageInfo from "./Interfaces/PilotageInfo";
import VesselMapObj from "./Interfaces/VesselMapObj";
import { InfoReporting } from "./Interfaces/InfoReporting";
import { Alert } from "./Interfaces/Alert";
import VesselDueToArrive from "./Interfaces/VesselDueToArrive";

export const formFieldTypes = {
  input: "input",
  text: "text",
  email: "email",
  password: "password",
  button: "button",
  submit: "submit",
  url: "url",
};

export const AppRoutes = {
  Login: "/",
  Register: "/register",
  VesselQuery: "/map",
}

export const API_ENDPOINT = process.env.REACT_APP_BE_ENDPOINT_DEP

export const API_Methods = {
  Login: '/login',
  Register: '/register',
  Table_view: '/api/table_pull'
}

export const API_Response_Success = 304;
export const API_Response_OK = 200

export const Response_Message = {
  Success: "Success",
  Error: "Error"
}

export const AlertType = {
  Success: "Success",
  Error: "Error"
}

export const tileLayer = {
  attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
}


export const themeColors: any = {
  sidebar: "#332941",
  myFleetCard: "#71608A"
}

export const filterColorList: string[] = [
  'DarkOrange', // Orange
  'MediumSeaGreen', // Green
  'MediumPurple', // Purple
  'HotPink', // Pink
  'DodgerBlue', // Blue
  'DarkOrchid', // Violet
  'Tomato', // Red
  'RoyalBlue', // Royal Blue
  'LimeGreen', // Lime Green
  'Gold', // Yellow
];

export const arrowColors: ArrowColor[] = [
  { 'red': 0, 'green': 0, 'blue': 0 },         // Black
  { 'red': 255, 'green': 0, 'blue': 0 },       // Red
  { 'red': 255, 'green': 255, 'blue': 0 },     // Yellow
  { 'red': 50, 'green': 205, 'blue': 50 },     // Lime Green
  { 'red': 0, 'green': 0, 'blue': 255 },       // Blue
  { 'red': 255, 'green': 20, 'blue': 147 },    // Deep Pink
  { 'red': 0, 'green': 255, 'blue': 255 },     // Cyan
  { 'red': 255, 'green': 0, 'blue': 255 },     // Magenta
  { 'red': 0, 'green': 128, 'blue': 0 },       // Forest Green
  { 'red': 255, 'green': 140, 'blue': 0 },     // Dark Orange
]

export const defaultBookmark: Bookmark[] = [
  {
    bookmarkId: 1,
    bookmarkName: "First",
    vessels: [
      {
        vesselName: "Jon",
        vesselImo: "123435",
        vesselMMSI: "214415432523",
        vesselType: "Cargo",
        searchTag: null
      },
      {
        vesselName: "Tom",
        vesselImo: "939358",
        vesselMMSI: "1234912934",
        vesselType: "Cargo",
        searchTag: null
      },
      {
        vesselName: "Alfred",
        vesselImo: "912833",
        vesselMMSI: "958432858283",
        vesselType: "Cargo",
        searchTag: null
      }
    ]
  },
  {
    bookmarkId: 2,
    bookmarkName: "Second",
    vessels: [
      {
        vesselName: "AScii",
        vesselImo: "123425",
        vesselMMSI: "9593493969",
        vesselType: "Cargo",
        searchTag: null
      },
      {
        vesselName: "Tom",
        vesselImo: "939358",
        vesselMMSI: "1234912934",
        vesselType: "Cargo",
        searchTag: null
      },
    ]
  }
]

export function convertToVesselMapObj(serverResponse: any) {
  const {
    NAME,
    latitudeDegrees,
    longitudeDegrees,
    imoNumber,
    heading,
    timeStamp,
    callsign,
    speed,
    'ETA - VesselFinder': ETA,
    DESTINATION,
    'ETD - VesselFinder': ETD,
    flag,
    query_time,
    mmsiNumber,
    duetoArriveTime,
    dueToDepart,
    locationFrom,
    locationTo,
    'Vessel Alias Names': vesselAliasNames
  } = serverResponse;
  console.log(ETA)
  return {
    name: NAME || '',
    latitude: latitudeDegrees || '',
    longitude: longitudeDegrees || '',
    IMO: imoNumber || '',
    course: heading || 0,
    eta: ETA ? new Date(ETA) : null,
    etd: ETD ? new Date(ETD) : null,
    timeStamp: new Date(timeStamp),
    speed,
    flag: flag,
    aliases : vesselAliasNames,
    callsign,
    MMSI:mmsiNumber,
    destination: DESTINATION || '',
    dueToArrive:duetoArriveTime ? new Date(duetoArriveTime) : null,
    dueToDepart: dueToDepart ? new Date(dueToDepart) : null,
    locationFrom: locationFrom ?? '',
    locationTo: locationTo ?? '',
  };
}

export function formatTimeDifference(diff: number) {
  const seconds = Math.floor(diff / 1000)
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);

  if (months > 0) {
    return months === 1 ? '1 month ago' : `${months} months ago`;
  } else if (days > 0) {
    return days === 1 ? '1 day ago' : `${days} days ago`;
  } else if (hours > 0) {
    return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
  } else if (minutes > 0) {
    return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
  } else {
    return diff === 1 ? '1 second ago' : `${diff} seconds ago`;
  }
}
export function convertStringToVesselMapObj(vessel: string) {

}

export function convertToPilotageInfo(serverResponse: any): PilotageInfo {
  return {
    pilotage_imo: serverResponse.pilotage_imo,
    pilotage_cst_dt_time: serverResponse.pilotage_cst_dt_time ? new Date(serverResponse.pilotage_cst_dt_time) : null,
    pilotage_arrival_dt_time: serverResponse.pilotage_arrival_dt_time ? new Date(serverResponse.pilotage_arrival_dt_time) : null,
    pilotage_onboard_dt_time: serverResponse.pilotage_onboard_dt_time ? new Date(serverResponse.pilotage_onboard_dt_time) : null,
    pilotage_start_dt_time: serverResponse.pilotage_start_dt_time ? new Date(serverResponse.pilotage_start_dt_time) : null,
    pilotage_end_dt_time: serverResponse.pilotage_end_dt_time ? new Date(serverResponse.pilotage_end_dt_time) : null,
    pilotage_nm: serverResponse.pilotage_nm,
    pilotage_loc_to_code: serverResponse.pilotage_loc_to_code,
    pilotage_loc_from_code: serverResponse.pilotage_loc_from_code
  };
}

export function convertToInfoReporting(vessel: VesselMapObj): InfoReporting {
  const result: InfoReporting = {
    id: String(vessel.id),
    destination: vessel.destination,
    eta: vessel.eta,
    etd: vessel.etd,
    pilotage_arrival_dt_time: vessel.pilotageInfo?.[0].pilotage_arrival_dt_time ?? null,
    pilotage_cst_dt_time: vessel.pilotageInfo?.[0].pilotage_cst_dt_time?? null,
    pilotage_end_dt_time: vessel.pilotageInfo?.[0].pilotage_end_dt_time?? null,
    pilotage_imo: vessel.pilotageInfo?.[0].pilotage_imo?? null,
    pilotage_loc_from_code: vessel.pilotageInfo?.[0].pilotage_loc_from_code?? null,
    pilotage_loc_to_code: vessel.pilotageInfo?.[0].pilotage_loc_to_code?? null,
    pilotage_nm: vessel.pilotageInfo?.[0].pilotage_nm?? null,
    pilotage_onboard_dt_time: vessel.pilotageInfo?.[0].pilotage_onboard_dt_time?? null,
    pilotage_start_dt_time: vessel.pilotageInfo?.[0].pilotage_start_dt_time?? null
  }
  return result
}

export function convertToNotificationEmail(vessel:InfoReporting) {
  const result = {
    id: String(vessel.id),
    destination: vessel.destination,
    eta: vessel.eta,
    etd: vessel.etd,
    pilotage_arrival_dt_time: vessel.pilotage_arrival_dt_time?.toLocaleString() ?? null,
    pilotage_cst_dt_time: vessel.pilotage_cst_dt_time?.toLocaleString()?? null,
    pilotage_end_dt_time: vessel.pilotage_end_dt_time?.toLocaleString()?? null,
    pilotage_imo: vessel.pilotage_imo?? null,
    pilotage_loc_from_code: vessel.pilotage_loc_from_code?? null,
    pilotage_loc_to_code: vessel.pilotage_loc_to_code?? null,
    pilotage_nm: vessel.pilotage_nm?? null,
    pilotage_onboard_dt_time: vessel.pilotage_onboard_dt_time?.toLocaleString()?? null,
    pilotage_start_dt_time: vessel.pilotage_start_dt_time?.toLocaleString()?? null
  }

  return result
}

export const timerIntervals = [10000, 15000, 20000, 25000, 30000, 35000, 35000, 40000, 50000, 60000, 90000]

export function checkIMOFormat(imo: string): boolean {
  // Regular expression to validate seven-digit number
  const imoRegex = /^\d{7}$/;

  if (!imoRegex.test(imo)) {
    return false; // Not in the correct format
  }

  // Calculate the check digit
  let sum = 0;
  for (let i = 0; i < 6; i++) {
    sum += parseInt(imo.charAt(i)) * (7 - i);
  }
  const checkDigit = sum % 10;

  // Convert the last character of the IMO string to an integer for comparison
  const lastDigit = parseInt(imo.charAt(6));

  // Check if the calculated check digit matches the last digit of the IMO number
  return checkDigit === lastDigit;
}

export const supportEmail = "support@sgtradex.com"

export function vesselInfoDateFormat(vesselInfoDate: Date | null| undefined) {
  if (!vesselInfoDate || vesselInfoDate === undefined) {
    return '-'
  }
  const dateCopy = new Date(vesselInfoDate)
  const dateString = dateCopy.toDateString().split(' ')
  const day = dateString[0].toUpperCase();
  
  const date = String(dateCopy.getDate()).padStart(2, '0')
  const month = dateString[1].toUpperCase()
  const year = dateString[3]
  const time = getTimeInHHMMFormat(dateCopy)

  return `(${day}) ${date} ${month} ${year} ${time} (SGT)`
}

export function convertToVesselDueToArrive(vessels: any[]): VesselDueToArrive[] {
  vessels = vessels.map((vessel) => {
    return {
      dueToArriveTime: new Date(vessel.duetoArriveTime),
      locationFrom: vessel.locationFrom,
      locationTo: vessel.locationTo,
      callSign: vessel.vesselParticulars.callSign,
      flag: vessel.vesselParticulars.flag,
      imoNumber: vessel.vesselParticulars.imoNumber,
      vesselName: vessel.vesselParticulars.vesselName
    }
  })
  vessels = vessels.sort((a,b) => a.dueToArriveTime.getTime() - b.dueToArriveTime.getTime())
  return vessels
}

export function extractDateRange(vessels: VesselDueToArrive[]): string[] {

  let dates = new Set<string>()
  vessels.forEach((vessel) => {
    dates.add(vessel.dueToArriveTime.toDateString())
  })

  const result = Array.from(dates)
  return result
}

export function convertVesselSearchResultToVesselInfo(vesselSearchResult: any) {
  return {
    vesselName: vesselSearchResult.vesselName,
    vesselImo: vesselSearchResult.imoNumber,
    vesselMMSI: vesselSearchResult.mmsiNumber,
    vesselType: vesselSearchResult.vesselType,
    vesselFlag: vesselSearchResult.flag || null,
    vesselCallsign: vesselSearchResult.callSign || null
  };
}

export function getDaysDiff(baseDate: Date | null | undefined, comparisonDate: Date | null| undefined) {
  if (!baseDate || !comparisonDate || baseDate === undefined || comparisonDate === undefined) {
    return 0
  }

  const baseDateOnly = new Date(baseDate.getFullYear(), baseDate.getMonth(), baseDate.getDate());
  const comparisonDateOnly = new Date(comparisonDate.getFullYear(), comparisonDate.getMonth(), comparisonDate.getDate());

  if (baseDateOnly.getTime() === comparisonDateOnly.getTime()) {
    return 0;
  } else {
    // Different date, calculate days difference
    const daysDifference = Math.ceil((comparisonDateOnly.getTime() - baseDateOnly.getTime()) / (1000 * 60 * 60 * 24));
    return daysDifference;
  }
}



export const getTimeInHHMMFormat = (date: Date | null | undefined) => {
  if (!date || date === undefined) {
    return ''
  }
  const hours = String(date.getHours()).padStart(2, '0'); // Ensure two digits for hours
  const minutes = String(date.getMinutes()).padStart(2, '0'); // Ensure two digits for minutes
  return `${hours}:${minutes}`;
};

export function getDecodedAccessToken(token: string): any {
  try {
    return jwtDecode(token);
  } catch (error) {
    return null;
  }
}

export function getFieldChangeLocation(base: VesselMapObj | undefined, cmp: VesselMapObj | undefined) {
  const changedFields: string[] = [];

  if (base === undefined || cmp === undefined) {
    return []
  }

  if (base.destination !== cmp.destination) {
    changedFields.push('destination');
  }

  if (base.eta !== cmp.eta) {
    changedFields.push('eta');
  }

  if (base.etd !== cmp.etd) {
    changedFields.push('etd');
  }

  return changedFields;
}

function haveExistingEmptyPilotage(pilotage: PilotageInfo): boolean {
  let haveExistingEmptyPilotage = true;

  // Check if any of the fields is not null
  if (
    pilotage.pilotage_imo !== null ||
    pilotage.pilotage_cst_dt_time !== null ||
    pilotage.pilotage_arrival_dt_time !== null ||
    pilotage.pilotage_onboard_dt_time !== null ||
    pilotage.pilotage_start_dt_time !== null ||
    pilotage.pilotage_end_dt_time !== null ||
    pilotage.pilotage_snapshot_dt !== null ||
    pilotage.pilotage_nm !== null ||
    pilotage.pilotage_loc_to_code !== null ||
    pilotage.pilotage_loc_from_code !== null
  ) {
    haveExistingEmptyPilotage = false;
  }

  return haveExistingEmptyPilotage;
}

export function getFieldChangePilotage(base: VesselMapObj | undefined, cmp: PilotageInfo | undefined) {
  const changedFields: string[] = [];
  console.log("BASE")
  console.log(base)
  if (base && base.pilotageInfo && haveExistingEmptyPilotage(base.pilotageInfo[0]) && !cmp){
    return changedFields
  }
  const fullPilotageChange = (!cmp && base && base.pilotageInfo) || (cmp && !base?.pilotageInfo)

  if (fullPilotageChange || base?.pilotageInfo?.[0].pilotage_cst_dt_time?.toISOString() !== cmp?.pilotage_cst_dt_time?.toISOString()) {
    changedFields.push('pilotage_cst_dt_time');
  }

  if (fullPilotageChange || base?.pilotageInfo?.[0].pilotage_arrival_dt_time?.toISOString() !== cmp?.pilotage_arrival_dt_time?.toISOString()) {
    changedFields.push('pilotage_arrival_dt_time');
  }

  if (fullPilotageChange || base?.pilotageInfo?.[0].pilotage_onboard_dt_time?.toISOString() !== cmp?.pilotage_onboard_dt_time?.toISOString()) {
    changedFields.push('pilotage_onboard_dt_time');
  }

  if (fullPilotageChange || base?.pilotageInfo?.[0].pilotage_start_dt_time?.toISOString() !== cmp?.pilotage_start_dt_time?.toISOString()) {
    changedFields.push('pilotage_start_dt_time');
  }

  if (fullPilotageChange || base?.pilotageInfo?.[0].pilotage_end_dt_time?.toISOString() !== cmp?.pilotage_end_dt_time?.toISOString()) {
    changedFields.push('pilotage_end_dt_time');
  }

  if (fullPilotageChange || base?.pilotageInfo?.[0].pilotage_nm !== cmp?.pilotage_nm) {
    changedFields.push('pilotage_nm');
  }

  if (fullPilotageChange || base?.pilotageInfo?.[0].pilotage_loc_to_code !== cmp?.pilotage_loc_to_code) {
    changedFields.push('pilotage_loc_to_code');
  }

  if (fullPilotageChange || base?.pilotageInfo?.[0].pilotage_loc_from_code !== cmp?.pilotage_loc_from_code) {
    changedFields.push('pilotage_loc_from_code');
  }

  return changedFields;
}

// export const sampleVessels: VesselMapObj[] = [
//   {
//     id: "1",
//     name: "Vessel 1",
//     type: "Container ship",
//     latitude: "12.3456",
//     longitude: "78.9101",
//     IMO: "1413241",
//     MMSI: "1",
//     visible: true,
//     course: 180,
//     loading: false,
//     colorIndex: 0,
//     pilotageInfo: {
//       "pilotage_imo": "1234567",
//       "pilotage_cst_dt_time": new Date(1711337400000),
//       "pilotage_arrival_dt_time": null,
//       "pilotage_onboard_dt_time": null,
//       "pilotage_start_dt_time": null,
//       "pilotage_end_dt_time": null,
//       "pilotage_nm": "SILVER STAR",
//       "pilotage_loc_to_code": "K45",
//       "pilotage_loc_from_code": "ABC123"
//     },
//     bookmarked: false,
//     eta: null,
//     etd: null,
//     callsign: "ABCD1",
//     speed: 20.1,
//     destination: null,
//     timeStamp: new Date(1711337400000),
//     hasPilotageData: true,
//   },
//   {
//     id: "2",
//     name: "Vessel 2",
//     type: "Container ship",
//     latitude: "23.4567",
//     longitude: "89.0123",
//     IMO: "8123573",
//     MMSI: "1",
//     visible: true,
//     course: 10,
//     loading: false,
//     colorIndex: 1,
//     pilotageInfo:
//     {
//       "pilotage_imo": "9876543",
//       "pilotage_cst_dt_time": new Date(1711411200000),
//       "pilotage_arrival_dt_time": null,
//       "pilotage_onboard_dt_time": null,
//       "pilotage_start_dt_time": null,
//       "pilotage_end_dt_time": null,
//       "pilotage_nm": "BLUE WHALE",
//       "pilotage_loc_to_code": "M30",
//       "pilotage_loc_from_code": "XYZ789"
//     },
//     bookmarked: false,
//     eta: null,
//     etd: null,
//     callsign: "ABCD1",
//     speed: 20.1,
//     destination: null,
//     timeStamp: new Date(1711337400000),
//     hasPilotageData: true,
//   },
// ];

export function parseWebSocketPilotage(pilotage: any) {
  let allPilotage = JSON.parse(pilotage['all_pilotage_data'])
  let currentPilotage = JSON.parse(pilotage['current_pilotage_data'])
  console.log(allPilotage)
  console.log(currentPilotage)
  pilotage = allPilotage.map((p:any) => {
    return {
      pilotage_imo: p.pilotage_imo,
      pilotage_arrival_dt_time: p.pilotage_arrival_dt_time != "None" ? new Date(p.pilotage_arrival_dt_time) : null,
      pilotage_cst_dt_time: p.pilotage_cst_dt_time!= "None" ? new Date(p.pilotage_cst_dt_time) : null,
      pilotage_end_dt_time: p.pilotage_end_dt_time!= "None" ? new Date(p.pilotage_end_dt_time) : null,
      pilotage_loc_from_code: p.pilotage_loc_from_code,
      pilotage_loc_to_code: p.pilotage_loc_to_code,
      pilotage_nm: p.pilotage_nm,
      pilotage_onboard_dt_time: p.pilotage_onboard_dt_time!= "None" ? new Date(p.pilotage_onboard_dt_time) : null,
      pilotage_snapshot_dt: p.pilotage_snapshot_dt!= "None" ? new Date(p.pilotage_snapshot_dt) : null,
      pilotage_start_dt_time: p.pilotage_start_dt_time!= "None" ? new Date(p.pilotage_start_dt_time) : null
    };
  })

  if (currentPilotage.length > 0) {
    console.log("LENGTH OF currentPilotage is > 0")
    console.log(currentPilotage.length)
    currentPilotage = {      
      pilotage_imo: currentPilotage[0].pilotage_imo,
      pilotage_arrival_dt_time: currentPilotage[0].pilotage_arrival_dt_time != "None" ? new Date(currentPilotage[0].pilotage_arrival_dt_time) : null,
      pilotage_cst_dt_time: currentPilotage[0].pilotage_cst_dt_time!= "None" ? new Date(currentPilotage[0].pilotage_cst_dt_time) : null,
      pilotage_end_dt_time: currentPilotage[0].pilotage_end_dt_time!= "None" ? new Date(currentPilotage[0].pilotage_end_dt_time) : null,
      pilotage_loc_from_code: currentPilotage[0].pilotage_loc_from_code,
      pilotage_loc_to_code: currentPilotage[0].pilotage_loc_to_code,
      pilotage_nm: currentPilotage[0].pilotage_nm,
      pilotage_onboard_dt_time: currentPilotage[0].pilotage_onboard_dt_time!= "None" ? new Date(currentPilotage[0].pilotage_onboard_dt_time) : null,
      pilotage_snapshot_dt: currentPilotage[0].pilotage_snapshot_dt!= "None" ? new Date(currentPilotage[0].pilotage_snapshot_dt) : null,
      pilotage_start_dt_time: currentPilotage[0].pilotage_start_dt_time!= "None" ? new Date(currentPilotage[0].pilotage_start_dt_time) : null
    }
  } else {
    currentPilotage = null
  }
  
  return {
    allPilotage: pilotage,
    currentPilotage: currentPilotage
  };
}

//Function to ensure that date fields in VesselMapObjs are not ISO string (They are defualted to be ISO strings when saved to local storage)
export function parseVesselMapObj(vessel: VesselMapObj): VesselMapObj {
  const parsedVessel: VesselMapObj = {
    ...vessel,
    eta: vessel.eta ? new Date(vessel.eta) : null,
    etd: vessel.etd ? new Date(vessel.etd) : null,
    timeStamp: vessel.timeStamp ? new Date(vessel.timeStamp) : null,
    pilotageInfo: vessel.pilotageInfo
      ? vessel.pilotageInfo.map((pi) => {
        return {
          ...pi,
          pilotage_cst_dt_time: pi.pilotage_cst_dt_time
            ? new Date(pi.pilotage_cst_dt_time)
            : null,
          pilotage_arrival_dt_time: pi.pilotage_arrival_dt_time
            ? new Date(pi.pilotage_arrival_dt_time)
            : null,
          pilotage_onboard_dt_time: pi.pilotage_onboard_dt_time
            ? new Date(pi.pilotage_onboard_dt_time)
            : null,
          pilotage_start_dt_time: pi.pilotage_start_dt_time
            ? new Date(pi.pilotage_start_dt_time)
            : null,
          pilotage_end_dt_time: pi.pilotage_end_dt_time
            ? new Date(pi.pilotage_end_dt_time)
            : null,
        }
      })
      : null,
      currentPilotageInfo: vessel.currentPilotageInfo
      ? 
         {
          ...vessel.currentPilotageInfo,
          pilotage_cst_dt_time: vessel.currentPilotageInfo.pilotage_cst_dt_time
            ? new Date(vessel.currentPilotageInfo.pilotage_cst_dt_time)
            : null,
          pilotage_arrival_dt_time: vessel.currentPilotageInfo.pilotage_arrival_dt_time
            ? new Date(vessel.currentPilotageInfo.pilotage_arrival_dt_time)
            : null,
          pilotage_onboard_dt_time: vessel.currentPilotageInfo.pilotage_onboard_dt_time
            ? new Date(vessel.currentPilotageInfo.pilotage_onboard_dt_time)
            : null,
          pilotage_start_dt_time: vessel.currentPilotageInfo.pilotage_start_dt_time
            ? new Date(vessel.currentPilotageInfo.pilotage_start_dt_time)
            : null,
          pilotage_end_dt_time: vessel.currentPilotageInfo.pilotage_end_dt_time
            ? new Date(vessel.currentPilotageInfo.pilotage_end_dt_time)
            : null,
        }
      : null,
  };

  return parsedVessel;
}

//Function to ensure that date fields in Alert are not ISO string (They are defualted to be ISO strings when saved to local storage)
export function parseAlert(alert: Alert): Alert {
  const parsedAlert: Alert = {
    ...alert,
    alarmTime: new Date(alert.alarmTime),
    stale: alert.stale.map(parseVesselMapObj),
    updated: alert.updated.map((info) => ({
      ...info,
      eta: info.eta ? new Date(info.eta) : null,
      etd: info.etd ? new Date(info.etd) : null,
      pilotage_arrival_dt_time: info.pilotage_arrival_dt_time
        ? new Date(info.pilotage_arrival_dt_time)
        : null,
      pilotage_cst_dt_time: info.pilotage_cst_dt_time
        ? new Date(info.pilotage_cst_dt_time)
        : null,
      pilotage_end_dt_time: info.pilotage_end_dt_time
        ? new Date(info.pilotage_end_dt_time)
        : null,
      pilotage_onboard_dt_time: info.pilotage_onboard_dt_time
        ? new Date(info.pilotage_onboard_dt_time)
        : null,
      pilotage_start_dt_time: info.pilotage_start_dt_time
        ? new Date(info.pilotage_start_dt_time)
        : null,
    })),
  };
  return parsedAlert;
}

export const alertTimerWait = 1

export function getCurrentDateTime() {
  const now = new Date();
  const day = now.getDate().toString().padStart(2, '0');
  const month = now.toLocaleString('default', { month: 'short' });
  const year = now.getFullYear();
  const time = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
  return `${day}-${month}-${year} ${time}`;
}
