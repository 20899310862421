import { useContext, useEffect, useRef, useState } from "react"
import { DigiPortPilotageData } from "../lib/Interfaces/DigiPortPilotageData"
import { DigiPortPilotageDataContext } from "../contexts/DigiPortPilotageDataContext"
import { initiateGetDigiPortVesselData } from "../api/API"
import useToken from "../contexts/useToken"
import { getHumanReadableDateFromDigiPortDataSource } from "../lib/helper"

interface DigiPortPilotageDataProps {
    vesselName?: string
}

const VesselInfoText: React.FC<{
    children?: React.ReactNode;
    className?: string;
    title: string;
  }> = ({ children, className, title }) => {
    return (
      <p
        className={className}
        style={{
          margin: 0,
        }}
      >
        <span className="font-bold">{title}:</span> {children}
      </p>
    );
  };

export const DigiPortPilotageDataComponent = ({
    vesselName
}: DigiPortPilotageDataProps) => {
    const isCalled = useRef(false)

    const [dataToRender, setDataToRender] = useState<DigiPortPilotageData>();

    const { socketId, data, fetchData } = useContext(DigiPortPilotageDataContext)
    const { token } = useToken()

    useEffect(() => {
        if (isCalled.current) {
            return
        }

        if (!vesselName) {
          return
        }

        const staleData = fetchData(vesselName)
        if (staleData) {
          setDataToRender(staleData)
          console.log('Rendered current data from context')
          return
        }

        async function initData() {
            if (socketId && vesselName) {
                await initiateGetDigiPortVesselData({
                    vesselName,
                    socketId,
                    token
                }).then(() => {
                    isCalled.current = true
                }).catch(() => {
                    console.error('Called init but failed to POST')
                })
            }
        }

        initData()
    }, [socketId, vesselName, isCalled])

    useEffect(() => {
        if (!vesselName) {
            return
        }
        const fetchedData = fetchData(vesselName)

        if (fetchedData) {
            setDataToRender(fetchedData)
        }
    }, [data, vesselName, fetchData])

    return (
            <>
              <p
                style={{
                    marginTop: "0.5rem",
                    marginBottom: "0.25rem",
                }}
                className="font-bold text-center underline"
              >
                DigiPort Pilotage Data
              </p>
              {
                vesselName && (
                  <>
                    <div className="flex flex-row">
                <div className="flex-1">
                  <VesselInfoText title="Vessel Name">
                    {dataToRender?.vessel_name ?? '-'}
                  </VesselInfoText>
                  <VesselInfoText title="Agent Name">
                    {dataToRender?.agent_name ?? '-'}
                  </VesselInfoText>
                  <VesselInfoText title="Last Port">
                    {dataToRender?.last_port ?? '-'}
                  </VesselInfoText>
                  <VesselInfoText title="Position">
                    {dataToRender?.posn ?? '-'}
                  </VesselInfoText>
                  <VesselInfoText title="ETA">
                    {getHumanReadableDateFromDigiPortDataSource(dataToRender?.eta)}
                  </VesselInfoText>
                  <VesselInfoText title="Declared Arrival">
                    {getHumanReadableDateFromDigiPortDataSource(dataToRender?.rep_arrival)}
                  </VesselInfoText>
                  <VesselInfoText title="Reported Arrival">
                    {getHumanReadableDateFromDigiPortDataSource(dataToRender?.rep_arrival)}
                  </VesselInfoText>
                </div>
                <div className="flex-1">
                  <VesselInfoText title="Call Sign">
                    {dataToRender?.callsign ?? '-'}
                  </VesselInfoText>
                  <VesselInfoText title="Flag">
                    {dataToRender?.flag ?? '-'}
                  </VesselInfoText>
                  <VesselInfoText title="Next Port">
                    {dataToRender?.next_port ?? '-'}
                  </VesselInfoText>
                  <VesselInfoText title="Status">
                    {dataToRender?.status ?? '-'}
                  </VesselInfoText>
                  <VesselInfoText title="ETD">
                    {dataToRender?.etd ?? '-'}
                  </VesselInfoText>
                  <VesselInfoText title="Declared Departure">
                    {getHumanReadableDateFromDigiPortDataSource(dataToRender?.dec_departure)}
                  </VesselInfoText>
                  <VesselInfoText title="Reported Departure">
                    {getHumanReadableDateFromDigiPortDataSource(dataToRender?.rep_departure)}
                  </VesselInfoText>
                </div>
              </div>
              <p />
              <div className="flex flex-row">
                <div className="flex-1">
                  <VesselInfoText title="Type">
                    {dataToRender?.type ?? '-'}
                  </VesselInfoText>
                  <VesselInfoText title="Gross Tonnage">
                    {dataToRender?.grt ?? '-'}
                  </VesselInfoText>
                  <VesselInfoText title="Beam">
                    {dataToRender?.beam ?? '-'}
                  </VesselInfoText>
                </div>
                <div className="flex-1">
                  <VesselInfoText title="Length of Overall (LOA)">
                    {dataToRender?.loa ?? '-'}
                  </VesselInfoText>
                  <VesselInfoText title="Purpose">
                    {(dataToRender?.purposes ?? []).join()}
                  </VesselInfoText>
                </div>
              </div>
              <p />
              {
                dataToRender?.movement_status && (
                  <>
                    <p
                      style={{
                          marginTop: "0.5rem",
                          marginBottom: "0.25rem",
                      }}
                      className="font-bold text-center"
                    >
                      Movement Status:
                    </p>
                    <table
                      style={{
                        border: "1px solid black",
                        margin: "0 auto",
                        borderCollapse: "collapse",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              padding: "8px",
                              border: "1px solid black"
                            }}
                          >
                            Timestamp
                          </th>
                          <th
                            style={{
                              padding: "8px",
                              border: "1px solid black"
                            }}
                          >
                            From
                          </th>
                          <th
                            style={{
                              padding: "8px",
                              border: "1px solid black"
                            }}
                          >
                            To
                          </th>
                          <th
                            style={{
                              padding: "8px",
                              border: "1px solid black"
                            }}
                          >
                            Remarks
                          </th>
                        </tr>
                      </thead>
                      {
                        dataToRender.movement_status.map((row, idx) => {
                          return (
                            <>
                              <tbody>
                                <tr
                                  key={idx.toString()}
                                  style={{ borderBottom: "1px solid black" }}
                                >
                                  <td
                                    style={{
                                      padding: "8px",
                                      border: "1px solid black",
                                    }}
                                  >
                                    {getHumanReadableDateFromDigiPortDataSource(row.date_time)}
                                  </td>
                                  <td
                                    style={{
                                      padding: "8px",
                                      border: "1px solid black",
                                    }}
                                  >
                                    {row.from}
                                  </td>
                                  <td
                                    style={{
                                      padding: "8px",
                                      border: "1px solid black",
                                    }}
                                  >
                                    {row.to}
                                  </td>
                                  <td
                                    style={{
                                      padding: "8px",
                                      border: "1px solid black",
                                    }}
                                  >
                                    {row.remarks}
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          )
                        })
                      }
                    </table>
                  </>
                )
              }
          </>
        )
      }
    </>
  )
}