import React from "react";
import { Controller, useForm } from "react-hook-form";
import { formFieldTypes } from "../lib/constants";
import Button from "./Button";
import Input from "./Input";
import styled, { css } from "styled-components";
import { sharedFlexCenter, sharedFlexSpaceBetween } from "../styles/global";
import { TextField } from "@mui/material";

interface RegisterFormProps {
  formFields: any;
  row?: boolean;
  isFormRow?: boolean;
}

const RegisterFormController: React.FC<RegisterFormProps> = ({
  formFields,
  row,
  isFormRow,
}) => {
  const { control, handleSubmit, formState } = useForm(); //TODO: controller is used to register external component(i.e Input, dropdown) values to form.
  const { submit } = formFieldTypes;
  const { isSubmitting } = formState;

  return (
    <Form>
      <FormFieldContainer $row={isFormRow} className="flex-wrap">
        {formFields?.fields?.map(
          (formField: any, index: React.Key | null | undefined) => (
            <FieldContainer $row={isFormRow} key={index} >
              <Field $row={row}>
                <Label>{formField.label}</Label>
                {formField.defaultValue !== "undefined" && ( //Temporary approach. will be checked once api's available
                  <Controller
                    name={formField.name}
                    control={control}
                    defaultValue={formField.defaultValue}
                    render={({ field }) => (
                      <>
                        <TextField
                          error={formField.validation ? formField.validation(field.value): false}
                          helperText={formField.validation && formField.validation(field.value) ? formField.helperText : ""}
                          className="w-full"
                          title={formField.name}
                          label={formField.label}
                          type={formField.inputType}
                          variant="standard"
                          value={field.value ?? formField.defaultValue}
                          onChange={(e) => field.onChange(e)}
                          defaultValue={formField.defaultValue}
                          placeholder=""
                        />
                      </>
                    )}
                  />
                )}
              </Field>
            </FieldContainer>
          )
        )}
      </FormFieldContainer>
      <div style={{
        padding: '0 32px',
        width: '100%',
        textAlign: 'center',
      }}>
        {formFields?.buttons?.map(
          (
            { name, type, onSubmitHandler, style, isLoading }: any,
            index: React.Key | null | undefined
          ) => (
            <Button
              key={index}
              title={name}
              clickHandler={
                type === submit
                  ? handleSubmit(onSubmitHandler)
                  : onSubmitHandler
              }
              isLoading={isLoading ?? false}
              buttonStyle={style}
            />
          )
        )}{" "}
      </div>
    </Form>
  );
};

export default RegisterFormController;

const Form = styled.form`
  ${sharedFlexCenter}
  width:100%;
  flex-direction: column;
`;

const FlexRow = css`
  flex-wrap: wrap;
  flex-direction: row;
`;

const FormFieldContainer = styled.div<{ $row?: boolean }>`
  ${sharedFlexCenter}
  width:100%;
  margin-bottom: 1rem;
  flex-direction: column;
  ${(props) => props.$row && FlexRow}
`;
const FieldContainer = styled.div<{ $row?: boolean }>`
  ${sharedFlexCenter}
  width: 50%;
  padding: 0 2rem;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

const Field = styled.div<{ $row?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  ${(props) => props.$row && sharedFlexSpaceBetween}
`;

const Label = styled.label`
  font-family: Lato, sans-serif;
  line-height: 1;
  margin-right: 1rem;
  text-transform: capitalize;
  width: max-content;
  font-size: 15px;
  font-weight: 500;
  text-align: left;
  color: white
`;
