import {
  ArrowBackIos,
  ArrowForwardIos,
  Close,
  Logout,
  Search,
} from "@mui/icons-material";
import React, { useEffect, useState, useRef } from "react";
import MyFleet from "./MyFleet";
import VesselMapObj from "../lib/Interfaces/VesselMapObj";
import { Button, Input, MenuItem, Select } from "@mui/material";
import {
  getVesselLocationIMOs,
  getVesselNameSearchResults,
  getVessels,
} from "../api/API";
import { CircleSpinner } from "react-spinners-kit";
import useToken from "../contexts/useToken";
import TableViewModal from "./TableViewModal";
import AlertModal from "./AlertModal";
import { AlertTypes } from "../lib/Interfaces/AlertTypes";
import { enqueueSnackbar } from "notistack";
import { Alert } from "../lib/Interfaces/Alert";
import { FieldChange } from "../lib/Interfaces/FieldChange";
import { InfoReporting } from "../lib/Interfaces/InfoReporting";
import VesselResultCardV2 from "./VesselResultCardV2";
import VesselInfo from "../lib/Interfaces/VesselInfo";
import { convertVesselSearchResultToVesselInfo } from "../lib/constants";
const { v4: uuidv4 } = require("uuid");

const tAndCUrl = require("../assets/VesselNow_T_AND_C.pdf");
interface SideBarContentProps {
  vessels: VesselMapObj[];
  selectedVessel: VesselMapObj[];
  handleNavigateToVessel: Function;
  handleSelectedVessel: Function;
  handleAddToFleetIMO: Function;
  handleRemoveVessel: Function;
  handleSelectAllVessels: Function;
  handleRetrieveVesselLocation: Function;
  handleCloseSidebar: Function;
  handleSetAvailColors: Function;
  checkVesselInFleet: Function;
  handleLogout: Function;
  updateFleet: Function;
  handleBookmark: Function;
  logout: Function;
  checkSearchLimit: Function;
  isLargeScreen: boolean;
  availColors: number[];
  spawnAlertTimer: Function;
  addAlert: Function;
  resetSelectedVessel: Function;
  handleRemoveVesselIMO: Function;
  userType: number;
  sid: String;
  pilotageTimeout: Function;
  handleAddToFleetName: Function;
}

const SideBarContent: React.FC<SideBarContentProps> = ({
  vessels,
  userType,
  isLargeScreen,
  checkVesselInFleet,
  handleCloseSidebar,
  checkSearchLimit,
  handleRetrieveVesselLocation,
  handleSelectAllVessels,
  handleSelectedVessel,
  selectedVessel,
  handleNavigateToVessel,
  handleRemoveVessel,
  handleAddToFleetIMO,
  handleAddToFleetName,
  handleSetAvailColors,
  spawnAlertTimer,
  updateFleet,
  availColors,
  handleBookmark,
  handleLogout,
  logout,
  addAlert,
  resetSelectedVessel,
  handleRemoveVesselIMO,
  sid,
  pilotageTimeout,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchBy, setSearchBy] = useState("name");
  const [openTable, setOpenTable] = useState(false);
  const [openAlertModal, setAlertModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [vesselNameSearchResults, setVesselNameSearchResults] = useState([]);
  const searchBoxRef = useRef<HTMLDivElement>(null);
  const sideBarRef = useRef<HTMLDivElement>(null);
  const { token, setToken, encodedToken, setEncodedToken } = useToken();
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchBoxRef.current &&
        sideBarRef.current &&
        !searchBoxRef.current.contains(event.target as Node) &&
        sideBarRef.current.contains(event.target as Node)
      ) {
        setOpenSearch(false);
      }
    };

    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (event.key == "Escape") {
        setOpenSearch(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    document.addEventListener("keydown", handleEscKeyPress, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [openSearch]);
  function handleCloseTable() {
    setOpenTable(false);
  }

  function handleOpenTable() {
    setOpenTable(true);
  }

  function handleCloseAlertModal() {
    setAlertModal(false);
  }

  function handlePageChange(newPage: any) {
    setCurrentPage(newPage);
  }

  function handleSearchByChange(searchByValue: string) {
    setSearchBy(searchByValue);
  }

  function checkVesselsValidityForAlarm(vessels: VesselMapObj[]) {
    console.log("Checking validity");
    let valid = true;
    vessels.forEach((vessel) => {
      console.log(vessel);
      if (vessel.latitude === "" && vessel.latitude === "") {
        valid = false;
      }
    });
    return valid;
  }

  function handleOpenAlertModal() {
    if (selectedVessel.length == 0) {
      enqueueSnackbar("No Vessels Selected", {
        variant: "error",
      });
    } else if (!checkVesselsValidityForAlarm(selectedVessel)) {
      enqueueSnackbar("Selection contains untracked vessels", {
        variant: "error",
      });
    } else {
      setAlertModal(true);
    }
  }

  async function handleCreateAlert(
    selectedVessels: VesselMapObj[],
    alertTypes: AlertTypes
  ) {
    const currentTime = new Date();
    const alarmTime = new Date(currentTime.getTime() + 1 * 5000);
    const fieldChange: FieldChange[] = [];
    const updated: InfoReporting[] = [];
    selectedVessel.forEach((vessel) => {
      fieldChange.push({
        imo: vessel.IMO,
        name: vessel.name,
        fieldChange: [],
      });
      updated.push({
        pilotage_imo: vessel.IMO,
      });
    });
    const staleVesselCopy = selectedVessel.map((obj) => ({ ...obj }));
    const alert: Alert = {
      timerId: uuidv4(),
      alarmTime,
      stale: staleVesselCopy,
      alertType: alertTypes,
      updated,
      fieldChange,
      pilotageCheck: [],
    };

    enqueueSnackbar("Alert successfully created!", {
      variant: "success",
    });

    addAlert(alert);
    spawnAlertTimer(alert.timerId, alarmTime);
    handleCloseAlertModal();
    resetSelectedVessel();
  }

  async function handleSearchIMO(searchString: string) {
    try {
      //Valid date search input
      let imos = searchString.split(",");
      let searchableIMO = [];
      if (checkSearchLimit(imos.length)) {
        return;
      }

      if (vessels.length == 10) {
        //To trigger alert
        handleAddToFleetIMO(imos[0], 0);
      } else {
        setLoading(true);
        if (imos.length + vessels.length > 10) {
          const diff = 10 - vessels.length;
          imos = imos.slice(0, diff);
        }

        const colorCopy = [...availColors];
        for (const imo of imos) {
          const cleanImo = imo.trim();
          if (!checkVesselInFleet(cleanImo)) {
            handleAddToFleetIMO(cleanImo, colorCopy.pop());
            searchableIMO.push(cleanImo);
          }
        }
        handleSetAvailColors(colorCopy);
        if (searchableIMO.length > 0) {
          const vesselLocation: {
            status: number;
            vessels: any[];
          } = await getVesselLocationIMOs(searchableIMO.toString(), token, sid);
          if (vesselLocation.status == 401) {
            logout();
          } else if (vesselLocation.status != 200) {
            searchableIMO.forEach((imo) => {
              handleRemoveVesselIMO(imo);
            });
            handleSetAvailColors(availColors);
          } else {
            for (const res of vesselLocation.vessels) {
              updateFleet(res);
            }
            pilotageTimeout(searchableIMO);
          }
        }

        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  }
  async function handleSearchName(searchString: string) {
    try {
      if (checkSearchLimit(1)) {
        return;
      }
      setLoading(true);
      setOpenSearch(true);
      //Name search occurs here
      //Results is okay populate the list.
      const vesselSearchResults: any = await getVesselNameSearchResults(
        searchString,
        token
      );
      if (vesselSearchResults.status == 401) {
        logout();
      } else if (vesselSearchResults.status != 200) {
        //Enqueue snackbar
      }

      setVesselNameSearchResults(
        vesselSearchResults.data.map((x: any) =>
          convertVesselSearchResultToVesselInfo(x)
        )
      );
      setLoading(false);
      //All vessels should be displayed over here
    } catch (e) {
      console.log(e);
    }
  }

  const handleAddToFleetNameLocationSearch = async (vessel: VesselInfo) => {
    const colorCopy = [...availColors];
    handleAddToFleetName(vessel, colorCopy.pop());
    handleSetAvailColors(colorCopy);
    setOpenSearch(false);
    const vesselLocation: {
      status: number;
      vessels: any[];
    } = await getVesselLocationIMOs(vessel.vesselImo, token, sid);
    if (vesselLocation.status == 401) {
      logout();
    } else if (vesselLocation.status != 200) {
      handleRemoveVesselIMO(vessel.vesselImo);
      handleSetAvailColors(availColors);
    } else {
      for (const res of vesselLocation.vessels) {
        updateFleet(res);
      }
      pilotageTimeout([vessel.vesselImo]);
    }
  };

  return (
    <>
      <div
        className="p-4 h-full overflow-y-auto overflow-x-hidden max-h-screen relative flex flex-col"
        ref={sideBarRef}
      >
        <div
          className={`flex ${
            isLargeScreen ? "flex-row justify-between" : "justify-end"
          } mb-5 `}
        >
          {isLargeScreen && (
            <Close
              htmlColor="white"
              className="hover:cursor-pointer mr-4"
              onClick={() => handleCloseSidebar()}
            />
          )}
          <Button
            className="w-28 py-2"
            color="error"
            variant="contained"
            startIcon={<Logout />}
            onClick={() => {
              handleLogout();
            }}
          >
            Logout
          </Button>
        </div>
        <>
          <div className="flex flex-row text-white items-baseline">
            <p className="mr-3">Search by: </p>
            <Select
              className=" bg-white text-white h-6"
              value={searchBy}
              label="Search by"
              onChange={(e) => handleSearchByChange(e.target.value)}
            >
              <MenuItem value={"name"}>Name</MenuItem>
              <MenuItem value={"imo"}>IMO</MenuItem>
            </Select>
          </div>
          <div className="h-min py-4 items-baseline justify-center">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (searchBy == "name") {
                  handleSearchName(searchValue);
                } else if (searchBy == "imo") {
                  handleSearchIMO(searchValue);
                }
              }}
              className="flex flex-row gap-2"
            >
              <div className="bg-gray-300 rounded-xl text-start flex flex-row mx-auto w-3/4 py-1">
                {loading ? (
                  <div className="w-min my-auto mx-2">
                    <CircleSpinner color={"grey"} size={20} />
                  </div>
                ) : (
                  <Search
                    className="m-1 hover:cursor-pointer"
                    onClick={() => {
                      if (searchBy == "name") {
                        handleSearchName(searchValue);
                      } else if (searchBy == "imo") {
                        handleSearchIMO(searchValue);
                      }
                    }}
                  />
                )}
                <Input
                  className={`w-full rounded-tl ${
                    loading ?? "pointer-events-none"
                  }`}
                  disableUnderline
                  placeholder={
                    searchBy == "imo" ? "Enter IMOs" : "Enter Vessel Name"
                  }
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  sx={{ fontSize: "14px" }}
                  autoFocus
                />
              </div>
              <Button
                type="submit"
                variant="contained"
                color="success"
                size="medium"
                className="whitespace-nowrap font-bold px-4 w-28"
                sx={{
                  fontSize: "0.75rem",
                }}
              >
                Search
              </Button>
            </form>
          </div>
        </>
        {openSearch ? (
          <>
            <div
              className="bg-white max-h-[65%] pt-0 p-4 items-baseline justify-center overflow-auto"
              ref={searchBoxRef}
            >
              {" "}
              <div className="bg-white sticky top-0 z-10">
                <h1 className="my-3 font-bold text-gray-600">Results</h1>
              </div>
              <div className="flex flex-col justify-center overflow-y-auto">
                {vesselNameSearchResults.length == 0 ? (
                  !loading ? (
                    <p>
                      No Results Found. Try searching by{" "}
                      <a
                        className="hover:cursor-pointer underline text-blue-400"
                        onClick={() => {
                          setSearchBy("imo");
                          setOpenSearch(false);
                          setSearchValue("");
                        }}
                      >
                        IMO
                      </a>{" "}
                      instead.
                    </p>
                  ) : (
                    <>Loading...</>
                  )
                ) : (
                  <>
                    {vesselNameSearchResults.map((vesselSearchResult: any) => {
                      return (
                        <>
                          <VesselResultCardV2
                            vessel={vesselSearchResult}
                            handleAddToFleet={async (vessel: VesselInfo) => {
                              await handleAddToFleetNameLocationSearch(vessel);
                            }}
                          />
                        </>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <MyFleet
            handleOpenTable={handleOpenTable}
            handleBookmark={handleBookmark}
            handleRetrieveVesselLocation={handleRetrieveVesselLocation}
            handleSelectAllVessels={handleSelectAllVessels}
            handleRemoveVessel={handleRemoveVessel}
            selectedVessel={selectedVessel}
            handleSelectedVessel={handleSelectedVessel}
            vessels={vessels}
            handleNavigateToVessel={handleNavigateToVessel}
            handleOpenAlertModal={handleOpenAlertModal}
            userType={userType}
          />
        )}

        <div className="mt-3">
          <h1
            className="w-full text-end text-slate-200 text-xs hover:text-blue-400 hover:cursor-pointer"
            onClick={() => {
              window.open(tAndCUrl, "_blank");
            }}
          >
            Terms & Conditions
          </h1>
        </div>
        {openTable ? (
          <TableViewModal
            userType={userType}
            vessels={vessels}
            handleCloseTable={handleCloseTable}
          />
        ) : (
          <></>
        )}
        {openAlertModal ? (
          <AlertModal
            selectedVessels={selectedVessel}
            handleAlertModalClose={handleCloseAlertModal}
            handleCreateAlert={handleCreateAlert}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default SideBarContent;
