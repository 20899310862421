import { useState } from "react"
import Token from "../lib/Interfaces/Token";

//Token context
export default function useToken() {
    //Retrieve encodedToken from localstorage (Not used)
    const getEncodedToken = () => {
        const tokenString = localStorage.getItem("encodedToken");
        return tokenString
    };
    //Retrieve token from localstorage
    const getToken = () => {
        const tokenString = localStorage.getItem("token");
        if (tokenString) {
            const userToken = JSON.parse(tokenString);
            return userToken;
        }
        return null
    };

    const [encodedToken, setEncodedToken] = useState(getEncodedToken());
    const [token, setToken] = useState(getToken());
    
    //Save encodedToken to localstorage (Not used)
    const saveEncodedToken = (userInfoToken: string) => {
        console.log("Saved encoded token")
        localStorage.setItem("encodedToken", userInfoToken);
        setEncodedToken(userInfoToken);
    };

    //Save token to localstorage
    const saveToken = (userInfoToken: string | null) => {
        if (userInfoToken) {
            console.log("Saved token")
            localStorage.setItem("token", JSON.stringify(userInfoToken));
        } else {
            localStorage.removeItem("token")
        }

        setToken(userInfoToken);
    };

    return {
        setEncodedToken: saveEncodedToken,
        encodedToken,
        setToken: saveToken,
        token
    }
}